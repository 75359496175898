<template>
  <div>
    <c-tab
      ref="ctomissTab"
      type="vertical"
      tabClassVertical="leftTabAlign"
      :inlineLabel="true"
      :dense="true"
      align="left"
      :height="tabHeight"
      :tabItems="tabItems"
      :splitter="splitter"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam.sync="tab.param"
          :contentHeight="contentHeight"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'symptomSurveyStatusDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaInvestigationPlanId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      splitter: { data: 120 },
      tab: '',
      surveies: [],
      editable: true,
      listUrl: '',
    };
  },
  computed: {
    tabItems() {
      let _items = []
      if (this.surveies && this.surveies.length > 0) {
        this.$_.forEach(this.surveies, item => {
          _items.push(
            { 
              name: item.heaMuscleWorkerSurveyId, 
              icon: '', 
              label: item.userName, 
              param: {
                heaMuscleWorkerSurveyId: item.heaMuscleWorkerSurveyId,
                readOnly: true,
              },
              component: () => import(`${'./symptomSurveyDetail.vue'}`) 
            },
          )
        })
      }
      return _items;
    },
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.muscleSystem.muscleSurvey.list.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.listUrl
      this.$http.type = 'GET';
      this.$http.param = {
        heaInvestigationPlanId: this.popupParam.heaInvestigationPlanId
      };
      this.$http.request((_result) => {
        this.surveies = _result.data
        this.tab = (this.surveies && this.surveies.length > 0 ? this.surveies[0].heaMuscleWorkerSurveyId : '');
      },);
    },
  }
};
</script>
