var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-tab", {
        ref: "ctomissTab",
        attrs: {
          type: "vertical",
          tabClassVertical: "leftTabAlign",
          inlineLabel: true,
          dense: true,
          align: "left",
          height: _vm.tabHeight,
          tabItems: _vm.tabItems,
          splitter: _vm.splitter,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: {
                    popupParam: tab.param,
                    contentHeight: _vm.contentHeight,
                  },
                  on: {
                    "update:popupParam": function ($event) {
                      return _vm.$set(tab, "param", $event)
                    },
                    "update:popup-param": function ($event) {
                      return _vm.$set(tab, "param", $event)
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }